<template>
    <div>
        <headers />
        <div class="container">
            <div>
                <div class="userCenterWarp">
                    <div class="userCenterTitle">个人中心</div>
                    <el-row :gutter="30">
                        <el-col :span="4">
                            <sideNav />
                        </el-col>
                        <el-col :span="20" class="mt-30">
                            <el-breadcrumb separator-class="el-icon-arrow-right" class="user-breadcrumb">
                                <el-breadcrumb-item><strong>基本信息</strong></el-breadcrumb-item>
                                <el-breadcrumb-item>修改密码</el-breadcrumb-item>
                            </el-breadcrumb>

                            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                                style="width: 730px;">
                                <el-form-item label="旧密码" prop="oldpassword">
                                    <el-input type="password" v-model="ruleForm.oldpassword"
                                        placeholder="请输入旧密码"></el-input>
                                </el-form-item>
                                <el-form-item label="新密码" prop="newpassword">
                                    <el-input type="password" v-model="ruleForm.newpassword"
                                        placeholder="请输入旧密码"></el-input>
                                </el-form-item>
                                <el-form-item label="确认密码" prop="repeatpassword">
                                    <el-input type="password" v-model="ruleForm.repeatpassword"
                                        placeholder="请输入确认密码"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="danger" class="custom-btn"
                                        @click="submitForm('ruleForm')">保存</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import headers from '@/components/header.vue'
import sideNav from './components/sideNav.vue'
export default {
    name: 'profile',
    components: {
        headers,
        sideNav
    },
    data () {
        let checkPwd = ((rule, value, callback) => {
            if (value.trim().length == 0) {
                callback(new Error("请输入确认密码"))
            } else if (value != this.ruleForm.newpassword) {
                callback(new Error("两次密码不一致"))
            } else {
                callback()
            }
        })
        return {
            ruleForm: {
                oldpassword: '',
                newpassword: '',
                repeatpassword: ''
            },
            rules: {
                oldpassword: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' },
                    { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
                ],
                newpassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
                ],
                repeatpassword: [
                    {
                        validator: checkPwd,
                        required: true,
                        trigger: "blur",
                    }
                ]
            }
        }
    },
    methods: {
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!')
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm (formName) {
            this.$refs[formName].resetFields()
        }
    },
}
</script>

<style scoped lang="less">
.header {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 30px;
}

.avatar-uploader {
    /deep/.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 75px;
            height: 75px;
            line-height: 75px;
            text-align: center;
        }
    }

    .avatar {
        width: 75px;
        height: 75px;
        display: block;
    }
}
</style>