<template>
    <div>
        <headers />
        <div class="container">
            <div>
                <div class="userCenterWarp">
                    <div class="userCenterTitle">个人中心</div>
                    <el-row :gutter="30">
                        <el-col :span="4">
                            <sideNav />
                        </el-col>
                        <el-col :span="20" class="mt-30">
                            <el-breadcrumb separator-class="el-icon-arrow-right" class="user-breadcrumb">
                                <el-breadcrumb-item><strong>基本信息</strong></el-breadcrumb-item>
                            </el-breadcrumb>
                            <el-form :rules="rules" ref="ruleForm" :model="ruleForm" label-width="100px"
                                style="width: 730px;">
                                <el-form-item label="头像">
                                    <div class="flex">
                                        <el-upload class="avatar-uploader"
                                            action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
                                            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                            <el-avatar v-if="ruleForm.avatar" :src="ruleForm.avatar"
                                                class="avatar"></el-avatar>
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                        <p class="el-upload__tip ml-15">支持jpg.png格式的图片，大小不要超过2M</p>
                                    </div>
                                </el-form-item>
                                <el-form-item label="用户名">
                                    <el-input v-model="ruleForm.username" placeholder="请输入用户名"></el-input>
                                </el-form-item>
                                <el-form-item label="修改密码">
                                    <el-link type="info" :underline="false"
                                        @click="$router.push('changePassword')">立即修改</el-link>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="danger" class="custom-btn" @click="onSubmit">保存</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import headers from '@/components/header.vue'
import sideNav from './components/sideNav.vue'
import { editInfo } from '@/api/apis'
export default {
    name: 'profile',
    components: {
        headers,
        sideNav
    },
    data () {
        return {
            ruleForm: {
                username: '',
                avatar: ''
            },
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                avatar: [
                    { required: true, message: '请上传头像', trigger: 'change' }
                ]
            }
        }
    },
    computed: {
        ...mapGetters(['isLogin', 'userInfo'])
    },
    created () {
        this.ruleForm.username = this.userInfo.username
        this.ruleForm.avatar = this.userInfo.avatar
    },
    methods: {
        handleAvatarSuccess (res, file) {
            this.ruleForm.avatar = URL.createObjectURL(file.raw)
        },
        beforeAvatarUpload (file) {
            const isJPG = file.type === 'image/jpeg'
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
            return isJPG && isLt2M
        },
        onSubmit () {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    editInfo(this.ruleForm).then(res => {
                        if (res.code === 1) {
                            this.$message.success(res.msg)
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            })
        }
    },
}
</script>

<style scoped lang="less">
.header {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 30px;
}

.avatar-uploader {
    /deep/.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 75px;
            height: 75px;
            line-height: 75px;
            text-align: center;
        }
    }

    .avatar {
        width: 75px;
        height: 75px;
        display: block;
    }
}
</style>